<div class="dialog-close" cdkFocusInitial (click)="cancel()"></div>

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div class="dashboard-chips" mat-dialog-content>
  <mat-form-field class="dashboard-chips-form">
    <mat-chip-list #phoneOpts>
      <mat-chip *ngFor="let chip of data.chips" [removable]="data.removable" (removed)="remove(chip)">
        {{chip}}
        <mat-icon matChipRemove *ngIf="data.removable">cancel</mat-icon>
      </mat-chip>
      <input id="email-input"
             placeholder="+ Add phone number"
             type="email"
             [formControl]="phoneCtrl"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputFor]="phoneOpts"
             (matChipInputTokenEnd)="add($event.value)">
    </mat-chip-list>
  </mat-form-field>

  <mat-error *ngIf="phoneCtrl?.invalid && (phoneCtrl?.dirty || phoneCtrl?.touched) && phoneCtrl?.errors?.['invalidPhoneNumber']"
             class="margin-t-20 margin-b-8">
    Please enter valid phone number</mat-error>
  <div class="message" *ngIf="data.message" [innerHTML]="data.message"></div>

  <div mat-dialog-actions class="ui-layout" ui-layout-align="end">
    <button mat-button class="dashboard-button action" (click)="cancel()">{{ data.cancelText }}</button>
    <button mat-button class="dashboard-button confirm focused"
            (click)="confirm()"
            [disabled]="(!data.chips.length && !phoneCtrl.value.length) || (phoneCtrl?.dirty || phoneCtrl?.touched) && phoneCtrl?.errors?.['invalidPhoneNumber']">{{ data.confirmText }}
    </button>
  </div>
</div>
