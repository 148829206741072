import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContentEditDialogComponent, DialogData } from '../components/content-edit-dialog/content-edit-dialog.component';


@Injectable()
export class ContentEditDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ContentEditDialogComponent>;

  public open(options) {

    this.dialogRef = this.dialog.open(ContentEditDialogComponent, {
      width: '50%',
      restoreFocus: false,
      data: {
        defaultText: options.defaultText,
        customText: options.customText,
        textType: options.textType,
        rtl: options.rtl,
        maxLength: options.maxLength === null ? undefined : options.maxLength || 250,
        customTitle: options.customTitle,
        isSenderLoginName: options.isSenderLoginName,
        smishingValidation: options.smishingValidation,
        showAutoTranslateCheckBox: options.showAutoTranslateCheckBox,
        showCharacterLimit: options.showCharacterLimit || false,
        specialTextValidation: options.specialTextValidation || false,
        specialText: options.specialText || '',
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }

  parseLoginName(loginName: string, sender: string): string[] {
    const loginNameEnding = sender.split('<')[1].split('@')[1].split('>')[0];
    return [(loginName), ('@' + loginNameEnding)];
  }

  public validateData(data: DialogData): { isValid: boolean; errors: { [key: string]: boolean } } {
    const errors: { [key: string]: boolean } = {};

    // Run individual validations
    if (data.isSenderLoginName && !this.validateSenderLoginName(data.customText)) {
      errors.invalidSenderLoginName = true;
    }

    if (data.smishingValidation && !this.validateNoSmishingLink(data.customText)) {
      errors.noSmishingLink = true;
    }

    if (data.smishingValidation && !this.validateMoreThanOneSmishingLink(data.customText)) {
      errors.moreThanOneSmishingLink = true;
    }

    if (this.containsHtmlTags(data.customText)) {
      errors.containsHtmlTags = true;
    }

    // Validate special text
    if (this.needsSpecialTextValidation(data.specialTextValidation, data.specialText)) {
      if (!this.specialTextExistsInCustomText(data)) {
        errors.missingSpecialText = true;
      }
    }
    // Determine overall validity
    const isValid = Object.keys(errors).length === 0;

    return { isValid, errors };
  }

  private validateSenderLoginName(data: string): boolean {
    if (!data) return false; // Must not be empty
    const nonAsciiRegex = /[^a-zA-Z0-9._-]/; // No non-ASCII characters
    const hasSpaces = /\s/; // No spaces
    return !nonAsciiRegex.test(data) && !hasSpaces.test(data);
  }

  private validateNoSmishingLink(data: string): boolean {
    const matches = data.match(/{{link}}/g);
    return !(matches === null); // No {{link}}
  }

  private validateMoreThanOneSmishingLink(data: string): boolean {
    const matches = data.match(/{{link}}/g);
    return !(matches?.length > 1); // At most one {{link}}
  }

  private containsHtmlTags(data: string): boolean {
    const regex = /<[^>]*>/; // Check for HTML tags
    return regex.test(data);
  }

  private needsSpecialTextValidation(specialTextValidation: boolean, specialText: string): boolean {
    return specialTextValidation && !!specialText;
  }

  private specialTextExistsInCustomText(data): boolean {
    const regex = new RegExp(data.specialText, 'g');
    return regex.test(data.customText);
  }
}
