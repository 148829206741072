<div class="snackbar" [style.border-left-color]="data.indicatorColor" [@bounceInUp]>
  <div class="snackbar-icon" *ngIf="data.showIcon" [style.color]="data.indicatorColor">
    <img *ngIf="data.iconSrc" [src]="data.iconSrc" alt="Icon" />
  </div>
  <div class="snackbar-message">
    {{ data.message }}
  </div>
  <a *ngIf="data.actionText" class="snackbar-close action" (click)="closeSnackbar('contact support')">{{data.actionText}}</a>
  <img *ngIf="data.showClose" class="snackbar-close" src="assets/svg/arrows-close.svg" alt="" (click)="closeSnackbar()">
</div>
