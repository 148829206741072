import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const phone = control.value;
    const phonePattern =
      /^(\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return phone && !phonePattern.test(phone) ? { invalidPhoneNumber: true } : null;
  };
}

@Component({
  selector: 'app-send-phone-dialog',
  templateUrl: './send-phone-dialog.component.html',
  styleUrls: ['./send-phone-dialog.component.scss']
})

export class SendPhoneDialogComponent implements OnInit {

  phoneCtrl = new FormControl('', [phoneNumberValidator()]);
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chips: Array<string>,
      removable: boolean | true,
      cancelText: string | null,
      confirmText: string | null,
      message: string | null,
      title: string | null,
    },
    private dialogRef: MatDialogRef<SendPhoneDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
      this.dialogRef.close(value);
  }

  public confirm() {
    if (this.phoneCtrl.value && this.phoneCtrl.valid) {
      this.add(this.phoneCtrl.value);
    } else if (this.phoneCtrl.value && !this.phoneCtrl.valid) {
      return;
    }
    this.close(this.data);
  }

  remove(chip: string): void {
    const index = this.data.chips.indexOf(chip);

    if (index >= 0) {
      this.data.chips.splice(index, 1);
      this.phoneCtrl.updateValueAndValidity();
    }
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

  add(value: string): void {
    if (this.phoneCtrl.valid) {
      this.data.chips.push(value.trim());
      this.phoneCtrl.setValue(null);
    }
  }
}
