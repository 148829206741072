<div class="dialog-close" cdkFocusInitial (click)="cancel()"></div>

<h1 mat-dialog-title>
  {{ data.title }}
</h1>

<div class="dashboard-chips" mat-dialog-content>
  <mat-form-field class="dashboard-chips-form">
    <mat-chip-list #emailOpts>
      <mat-chip *ngFor="let chip of data.chips" [removable]="data.removable" (removed)="remove(chip)">
        {{chip}}
        <mat-icon matChipRemove *ngIf="data.removable">cancel</mat-icon>
      </mat-chip>
      <input id="email-input"
             *ngIf="data.addEmailPermission"
             placeholder="+ Add email address"
             type="email"
             [formControl]="emailCtrl"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputFor]="emailOpts"
             (matChipInputTokenEnd)="add($event.value)"
             (paste)="paste($event)">
    </mat-chip-list>
  </mat-form-field>

  <mat-error *ngIf="emailCtrl?.invalid && (emailCtrl?.dirty || emailCtrl?.touched) && emailCtrl?.errors?.['invalidEmail']"
             class="margin-t-20 margin-b-8">
    Please enter valid email address</mat-error>
  <div class="message" *ngIf="data.message" [innerHTML]="data.message"></div>

  <div mat-dialog-actions class="ui-layout" ui-layout-align="end">
    <button mat-button class="dashboard-button action" (click)="cancel()">{{ data.cancelText }}</button>
    <button mat-button class="dashboard-button confirm focused"
            (click)="confirm()"
            [disabled]="(!data.chips.length && !emailCtrl.value.length) || (emailCtrl?.dirty || emailCtrl?.touched) && emailCtrl?.errors?.['invalidEmail']">{{ data.confirmText }}
    </button>
  </div>
</div>
