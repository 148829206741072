import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { CustomSnackbarOptions } from '@shared/components/custom-snackbar/custom-snackbar.service';

interface CustomSnackbarDialogData extends CustomSnackbarOptions {
  message: string;
  indicatorColor: string;
}

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  standalone: true,
  imports: [MatSnackBarModule, CommonModule],
  animations: [
    trigger('bounceInUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms cubic-bezier(0.68, -0.55, 0.27, 1.55)', style({ transform: 'translateY(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class CustomSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarDialogData,
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {}

  closeSnackbar(action = '') {
    if (action) {
      this.snackBarRef.dismissWithAction();
    } else {
      this.snackBarRef.dismiss();
    }
  }

}
