import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '@shared/services/user/user.service';
import { FormAction } from '@shared/interfaces/FormAction';

@Component({
  selector: 'app-set-password',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class SetPasswordComponent implements OnInit {

  public form: UntypedFormGroup;
  public exclude: string[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public pageType: string = 'set-password';
  public errorText: string = '';
  public allValidationPassed = false;

  public showValidatePassword: boolean = false;
  public validationError = {
    character: false,
    number: false,
    upperCase: false,
    specialCharacter: false,
  };
  public validationDone = false;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private userService: UserService,
              private route: ActivatedRoute,) {
    this.pageTitle = 'Set Password';
    this.exclude = ['persist'];
    const password = this.fb.control('', [Validators.required, Validators.minLength(8)]);
    this.form = this.fb.group({
      password,
      validatePassword: this.fb.control('', [Validators.required, Validators.minLength(8)])
    }, { validator: this.passwordMatchValidator });

    this.exclude = [];
    this.formActionBtns = [
      {
        type: 'button',
        class: 'login-submit-btn',
        label: 'Set Password',
        action: ''
      }

    ];
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userService.validateActivationLink(params['token']).subscribe();
    });
  }

  send() {
    this.validateInput();
    if (this.form.valid && this.allValidationPassed) {
      this.userService.setPassword(this.form.value['password']).subscribe(() => {
        this.router.navigate(['/', 'login']);
      });
    }
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('validatePassword');

    if (!password || !confirmPassword) {
      return null;
    }

    return password.value === confirmPassword.value || !confirmPassword.value ? null : { 'passwordMismatch': true };
  }


  private validateInput() {
    this.validationDone = true;
    const password = this.form.get('password').value;
    this.validationError.character = password.length >= 8;
    this.validationError.number = password.match(/\d+/g) !== null;
    this.validationError.upperCase = password.match(/[A-Z]/) !== null;
    this.validationError.specialCharacter = password.match(/[^A-Za-z0-9]/) !== null;
    const keys = Object.keys(this.validationError);
    this.allValidationPassed = keys.every(key => this.validationError[key]);
  }

}
