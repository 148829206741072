import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    const emailPattern =
      /^[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return email && !emailPattern.test(email) ? { invalidEmail: true } : null;
  };
}

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss']
})

export class SendEmailDialogComponent implements OnInit {
  emailCtrl = new FormControl('', [emailValidator()]);
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chips: Array<string>,
      removable: boolean | true,
      cancelText: string | null,
      confirmText: string | null,
      message: string | null,
      title: string | null,
      addEmailPermission: boolean | true;
    },
    private dialogRef: MatDialogRef<SendEmailDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
      this.dialogRef.close(value);
  }

  public confirm(): void {
    if (this.emailCtrl.value && this.emailCtrl.valid) {
      this.add(this.emailCtrl.value);
    } else if (this.emailCtrl.value && !this.emailCtrl.valid) {
      return;
    }
    this.close(this.data);
  }

  remove(chip: string): void {
    const index = this.data.chips.indexOf(chip);

    if (index >= 0) {
      this.data.chips.splice(index, 1);
      this.emailCtrl.updateValueAndValidity();
    }
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

  add(value: string): void {
    if (this.emailCtrl.valid) {
      this.data.chips.push(value.trim());
      this.emailCtrl.setValue(null);
    }
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    event.clipboardData
    .getData('Text').trim()
    .split(/;|:|,|\n/)
    .forEach(value => {
      value = value.trim().replace('[', '').replace(']', '');
      const contact = value.match(/<([^']+)>/);
      if (contact) {
        this.data.chips.push(contact[1]);
      } else if (re.test(value)) {
        this.data.chips.push(value);
      }
    });
  }
}
