import { Component, OnInit, NgZone } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { loginSuccess } from '@app/auth/store/auth.actions';
import { Store } from '@ngrx/store';
import { CustomSnackbarService } from '@shared/components/custom-snackbar/custom-snackbar.service';

@Component({
  selector: 'app-loginsso',
  templateUrl: './loginsso.component.html',
  styleUrls: ['./loginsso.component.scss']
})
export class LoginssoComponent implements OnInit {

  constructor(
    private customSnackbarService: CustomSnackbarService,
    private store: Store,
    private userService: UserService, private router: Router,
    private ngZone: NgZone, private route: ActivatedRoute) {

    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          Auth.currentAuthenticatedUser()
            .then(user => {
              const idToken = user.signInUserSession.getIdToken().getJwtToken();
              this.ngZone.run(() => this.login(idToken));
            })
          break;
        case 'signIn_failure':
          this.customSnackbarService.showSnackbar(`Error in signing in. data:${data}, payload:${data.payload}`, 'warning');
          break;
      }
    }

    Hub.listen('auth', listener)
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const code = 'code' in params;
      const error = params['error_description'];

      if (error) {
        this.customSnackbarService.showSnackbar(params['error_description'], 'warning');
      } else if (!code) {
        this.handleSSO()
      }
    });
  }

  handleSSO() {
    Auth.federatedSignIn();
  }

  login(id_token: string) {
    this.userService.login({ id_token }, 'sso-login/').subscribe(res => {
      const deepCopyData = JSON.parse(JSON.stringify(res));
      this.store.dispatch(loginSuccess({user: deepCopyData}));
      this.router.navigate(['/']);
    });
  }

}
