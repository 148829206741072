import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { UiHttp } from '@shared/services/ui-http/ui-http.service';
import { User, UserAuthResponse, SSOData } from '@interfaces/auth.interface';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import * as pbi from 'powerbi-client';
import { Auth } from 'aws-amplify';
import { PowerBIReports, PowerBIResponse, SwitchCustomerResponse } from '@app/auth/store/auth.interface';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { forceLogout } from '@app/auth/store/auth.actions';
import { LoadingService } from '@shared/services/ui-http/loading.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _userData: any;
  private powerBi: any;
  private pbiEmbedUrlBase = 'https://app.powerbi.com/reportEmbed';
  private tokenRefreshTimeout;

  pbiReport: any;
  returnUrl: string;
  pbiInitialized: boolean;
  reportsData: BehaviorSubject<PowerBIReports> = new BehaviorSubject<PowerBIReports>(null);

  constructor(private http: UiHttp,
              private store: Store,
              private loadingService: LoadingService,
              private router: Router) {
    this.powerBi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.pbiInitialized = false;
    if (localStorage.getItem('customer')) {
      this.userData = JSON.parse(localStorage.getItem('customer'));
    }
  }

  set userData(user: any) {
    localStorage.setItem('customer', JSON.stringify(user));
    this._userData = user;
  }

  get userData(): any {
    return this._userData;
  }

  get desktopPages() {
    const pages = this.userData.selected_customer.report_pages
      .filter(p => !p.displayName.toLowerCase().includes('mobile') && !p.displayName.toLowerCase().includes('resilience'));
    pages.forEach(p => {
      const value = p.displayName.split(' ').join('-');
      p.route = `/report/${value}`;
      p.childRoute = value;
    });
    return pages;
  }

  get currentBranding() {
    if (this.userData && this.userData.selected_customer && this.userData.selected_customer.selected_branding) {
        return this.userData.selected_customer.selected_branding;
    }
    return {'id': null};
  }

  get permissions() {
    return this.userData.selected_customer.permissions;
  }

  login(data: User | SSOData, loginURL: string): Observable<any> {
    return new Observable(ob => {
      this.http.post(loginURL, data)
        .pipe(map(response => {
          const { id, ...other } = response;
          return {...other, demo: response.selected_customer.demo, logo: response.selected_customer.logo, user_id: id};
        }),
          catchError(err => {
            ob.error(err);
            return throwError(err);
          }))
        .subscribe((res: UserAuthResponse) => {
        if (res['demo'] && !window.location.href.includes(environment.demoDomain)) {
          window.location.href = `${environment.demoDomain}`;
          return;
        }

        if (!res['demo'] && window.location.href.includes(environment.demoDomain)) {
          window.location.href = `${environment.nonDemoDomain}`;
          return;
        }

        res['cognito'] = data.hasOwnProperty('id_token');
        this.userData = res;
        ob.next({
          success: true,
          ...res
        });
        ob.complete();
      });
    });
  }

  twoFactorsAuthentication(confirmation_code: number, remember_me: Boolean): Observable<any> {
    console.log('twoFactorsAuthentication');
    return new Observable(ob => {
      this.http.post('2fa/', {
        code: confirmation_code,
        remember_me: remember_me
      }).subscribe((res: any) => {
        const { id, ...other } = res;
        const result = {...other, demo: res.selected_customer.demo, logo: res.selected_customer.logo, user_id: id};
        this.userData = Object.assign({}, JSON.parse(localStorage.getItem('customer')), result);
        ob.next(result);
        ob.complete();
      },
        (error => {
          console.log('2FA error: ', error);
        })
        );
    });
  }

  resendMfa(): Observable<any> {
    return this.http.post('resend-mfa/');
  }

  resetPassword(email: string): Observable<null> {
    return this.http.post('reset-password/', {
      email: email
    }, false);
  }

  validateActivationLink(inviteKey): Observable<any> {
    return this.http.post('activate/', { key: inviteKey });
  }

  setPassword(password: string): Observable<null> {
    return this.http.post('set-password/', {
      password: password,
    });
  }

  changePassword(oldPassword, newPassword): Observable<null> {
    return this.http.post('change-password/', {
      password_old: oldPassword,
      password: newPassword,
    });
  }

  getPowerbiData(): Observable<PowerBIResponse> {
    return this.http.post('get-powerbi-data/', {
      cp: this.userData.selected_customer.cp_id,
      dashboard: true,
    });
  }

  logout() {
    this.http.get('logout/').subscribe(() => { });
    const cognito = JSON.parse(localStorage.getItem('customer'))['cognito'];
    localStorage.removeItem('customer');
    this.store.dispatch(forceLogout());
    if (cognito) {
      Auth.signOut()
        .catch(err => this.router.navigate(['/loginsso']))
        .then(() => this.router.navigate(['/loginsso']));
    } else {
      this.router.navigate(['/login']);
    }
  }

  switchCustomer(customer_id: number): Observable<SwitchCustomerResponse> {
    return this.http.post('switch-customer/', { cp_id: this.userData.user_id, customer_id });
  }

  switchBranding(brandingId) {
    this.userData.selected_customer.selected_branding = this.userData.selected_customer.brandings.find(item => item.id === brandingId);
    this.userData = this.userData; // to trigger 'set userData()'
    window.location.reload();
  }

  preloadPbi() {
    this.powerBi.preload({ type: 'report', embedUrl: this.pbiEmbedUrlBase });
  }

  updatePbiToken() {
    console.log('updatePbiToken');
    this.getPowerbiData().subscribe(data => {
      console.log('updatePbiToken. getPowerbiData. data: ', data);
      this.pbiReport.setAccessToken(data.power_bi_token.token).then(() => {
        console.log('pbi report refresh');
        this.pbiReport.refresh();
      });
    });
  }

  initializePbi(settings) {
    console.log('initializePbi. Settings: ', settings);
    this.pbiInitialized = true;
    this.getPowerbiData().subscribe(data => {
      this.loadingService.pushRequest();
      console.log('getPowerbiData, data: ', data);
      this.userData = Object.assign({}, this.userData, data);
      const el = document.getElementById('pbi-el');
      document.getElementById('pbi-el').style.display = 'none';

      const config: any = {
        embedUrl: this.pbiEmbedUrlBase,
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.Read,
        type: this.userData.type,
        accessToken: this.userData.power_bi_token.token,
        id: this.userData.id,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: false,
          customLayout: {
            displayOption: pbi.models.DisplayOption.FitToWidth
          },
          layoutType: pbi.models.LayoutType.Custom,
        },
      };
      console.log('initializePbi config: ', config);

      if (settings['mode'] === 'mobile') {
        // pass
      } else {
        if (settings['pageName']) {
          config.pageName = settings['pageName'];
        } else {
          config.pageName = this.desktopPages[0].name;
        }
      }

      if (settings['mode'] === 'mobile' && settings['layout']) {
        config.settings.layoutType = settings['layout'];
      }

      this.pbiReport = this.powerBi.embed(el, config);
      const reportsData = this.reportsData;
      const loadingService = this.loadingService;
      this.pbiReport.on("loaded", function() {
          reportsData.next({...data.reports});
          loadingService.popRequest();
      });

      this.pbiReport.on('error', (err) => {
        this.pbiInitialized = false;
        loadingService.popRequest();
        console.log('pbiReport on error: ', err);
        if (err?.detail?.message === 'TokenExpired') {
          console.log('err.detail.message === \'TokenExpired\'');
          clearTimeout(this.tokenRefreshTimeout);
          this.tokenRefreshTimeout = setTimeout(() => this.updatePbiToken(), 500);
        }
      });
    },
      // on get powerbi error
      (err => {
        console.log('get powerbi error: ', err);
        this.pbiInitialized = false;
        this.loadingService.popRequest();
        if (err?.detail?.message === 'TokenExpired') {
          console.log('get powerbi err.detail.message === \'TokenExpired\'');
          clearTimeout(this.tokenRefreshTimeout);
          this.tokenRefreshTimeout = setTimeout(() => this.updatePbiToken(), 500);
        }
      }));
  }

  sendGaEvent(category, label, action) {
    (<any>window).ga('send', 'event', {
      eventCategory: category,
      eventLabel: label,
      eventAction: action,
    });
  }

}
