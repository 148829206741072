import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { FormAction } from '@shared/interfaces/FormAction';
import { loginSuccess } from '@app/auth/store/auth.actions';
import { Store } from '@ngrx/store';
import { CustomSnackbarService } from '@shared/components/custom-snackbar/custom-snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class LoginComponent implements OnInit {

  public form: UntypedFormGroup;
  public exclude: string[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public pageType: string = 'login';
  public errorText: string = '';
  public showPassword: boolean = false;

  private lockedAccounts = {};

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private userService: UserService,
    private store: Store,
    private customSnackbarService: CustomSnackbarService) {

    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [Validators.required, Validators.minLength(8)]),

    });
    this.pageTitle = 'Welcome';
    this.exclude = ['persist'];
    this.formActionBtns = [
      {
        type: 'a',
        class: 'forgot-pass',
        label: 'Forgot password?',
        action: () => {
          this.router.navigate(['/', 'forgot-password']);
        }
      },
      { type: 'button', class: 'login-submit-btn', label: 'Log in', action: '' }
    ];
  }

  ngOnInit() {}


  send() {
    if (this.form.valid) {
      this.userService.login(this.form.value, 'get-api-token/').subscribe(res => {
        const deepCopyData = JSON.parse(JSON.stringify(res));
        this.store.dispatch(loginSuccess({user: deepCopyData}));
        if (this.userService.returnUrl) {
          this.router.navigateByUrl(this.userService.returnUrl).then(() => this.userService.returnUrl = undefined);
        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        if (error?.error?.non_field_errors && error.error.non_field_errors[0] === 'Wrong email/password pair.') {
          this.errorText = 'That email and password combination is incorrect';
          this.incrementFailedLoginAttempt(this.form.controls.email.value);
        }
        this.form.controls.email.markAllAsTouched();
        this.form.controls.email.setErrors({credentials: 'That email and password combination is incorrect'});
        this.form.reset();
      });
    }
  }

  private incrementFailedLoginAttempt(email: string) {
    this.lockedAccounts[email] ? this.lockedAccounts[email]+=1 : this.lockedAccounts[email] = 1;
    if (this.lockedAccounts[email] >= 10) {
      this.form.disable();
      const snackbar = this.customSnackbarService.showSnackbar('Multiple failed login attempts locked your account.', 'warning',
        'Contact Support')
      snackbar.afterDismissed().subscribe((res)=> {
        if (res.dismissedByAction) {
          window.location.href = 'https://support.cybeready.com/';
        }
      });
    }
  }

}
