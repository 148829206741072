import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from './custom-snackbar.component';

export interface CustomSnackbarOptions {
  showIcon?: boolean;
  iconSrc?: string;
  showClose?: boolean;
  dismissedByAction?: boolean;
  actionText?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(
    message: string,
    type: 'warning' | 'success' | 'info',
    actionText = '',
    options: { showIcon?: boolean; iconSrc?: string; showClose?: boolean, dismissedByAction?: boolean } = {},
  ) {
    const typeColors = {
      warning: '#F87060',
      success: '#59B198',
      info: '#BDD5F8'
    };
    const typeIcons = {
      warning: '/assets/svg/info-warning-red.svg',
      success: '/assets/svg/checkmark_green.svg',
      info: '/assets/svg/info-icon.svg'
    };

    return this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {
        message,
        indicatorColor: typeColors[type],
        showIcon: options.showIcon !== false, // Default to true
        iconSrc: options.iconSrc || typeIcons[type],
        showClose: options.showClose !== false, // Default to true
        dismissedByAction: options.dismissedByAction,
        actionText
      },
      duration: 10000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar-container'
    });
  }
}
